import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BlogDetail.css';
import { fetchMarkdownContent } from '../utils';
import BlogPost from '../components/BlogPost';

export default function BlogDetail() {
  const { slug }: any = useParams<{ slug: string }>();
  const [markdownContent, setMarkdownContent] = useState<string>('');
  const [frontmatter, setFrontmatter] = useState<any>({});

  useEffect(() => {
    fetchMarkdownContent(slug).then((fetchData: any) => {
      setFrontmatter(fetchData?.data);
      setMarkdownContent(fetchData?.content);
    });
  }, [slug]);

  return (
    <div className="blog-detail-container">
      {frontmatter && markdownContent && (
        <div className="blog-detail-content">
          <BlogPost content={markdownContent} />
        </div>)}
    </div >
  );
};