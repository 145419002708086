// App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar';
import BlogDetail from './pages/BlogDetail';
import Home from './pages/Home';
import "./App.css";

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/article/:slug" element={<BlogDetail />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};