// Home.tsx
import React from 'react';
import '../styles/Home.css';
import BlogList from '../components/BlogList';
import { Container } from 'react-bootstrap';

const Home: React.FC = () => {

  return (
    <Container fluid id='home' className="home">
      <h1 className="welcome-message">CodeCrafters, Welcome!</h1>
      <BlogList />
    </Container>
  );
};

export default Home;
