// Header.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from 'react-bootstrap';
import { CgLink } from "react-icons/cg";
import { AiOutlineHome } from "react-icons/ai";
const NavBar: React.FC = () => {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }
  window.addEventListener("scroll", scrollHandler);
  const pageRedirect = (link: string) => {
    window.location.href = link === "portfolio" ? 'https://karim.premieronetech.com/projects' : 'https://www.premieronetech.com/';
  };
  return (
    <div className="header">
      <Navbar expanded={expand}
        fixed="top"
        expand="md"
        className={navColour ? "sticky" : "navbar"}>
        <Container>
          <Navbar.Brand href="/" className="d-flex">
            <img src={'/assets/images/logo.png'} className="img-fluid logo" alt="brand" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : true);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home" style={{ display: "flex", alignItems: "baseline" }}>
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                  <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={() => pageRedirect("portfolio")}
                >
                  <CgLink style={{ marginBottom: "2px" }} /> Back to Portfolio
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/"
                  onClick={() => pageRedirect("company")}
                >
                  <CgLink style={{ marginBottom: "2px" }} /> PremierOne Tech
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
