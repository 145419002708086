// components/BlogPost.tsx
import React, { useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../styles/BlogDetail.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FaCopy, FaMoon, FaSun } from 'react-icons/fa';
interface BlogPostProps {
  content: string;
}

const BlogPost: React.FC<BlogPostProps> = ({ content }) => {
  const syntaxHighlighterRef: any = useRef<SyntaxHighlighter>(null);
  const [isDark, setIsDark] = useState<boolean>(false);
  const [color, setColor] = useState<string>("black");

  function codeSnippetColorHandler() {
    setIsDark(!isDark)
    setColor(!isDark ? "white" : 'black')
  }

  return <Markdown
    children={content}
    components={{
      code(props) {
        const { children, className, node, ...rest } = props;
        const match = /language-(\w+)/.exec(className || '');
        const codeString = String(children).replace(/\n$/, '');

        return match ? (
          <div className="code-container">
            <SyntaxHighlighter
              ref={syntaxHighlighterRef}
              {...rest}
              PreTag="div"
              children={codeString}
              language={match[1]}
              customStyle={{ minHeight: "5dvh", display: 'flex', alignItems: "center" }}
              style={isDark ? a11yDark : a11yLight}
            />
            <div className="icon-container">
              <CopyToClipboard text={codeString}>
                <button className='icon' aria-label="Copy to Clipboard">
                  <FaCopy className='icon' size={20} color={color} onClick={() => {
                  }} />
                </button>
              </CopyToClipboard>
              {isDark ?
                <button className='icon' aria-label="Copy to Clipboard">
                  <FaSun className='icon' size={20} color={color} onClick={codeSnippetColorHandler} />
                </button>
                : <button className='icon' aria-label="Copy to Clipboard">
                  <FaMoon className='icon' size={20} color={color} onClick={codeSnippetColorHandler} />
                </button>}
            </div>
          </div>
        ) : (
          <code {...rest} className={className}>
            {children}
          </code>
        );
      },
    }}
  />;
};

export default BlogPost;
